import React from 'react'
import Header from './Header'
import MainContent from './MainContent'
import Footer from './Footer'
export default function Home() {
    return (
        <>
         {/* header section start */}
            <Header />
            {/* header section start */}
            {/* main content start */}
           <MainContent />
           {/* main content start */}
            {/* footer section start */}
            <Footer />
            {/* footer section end */}

        </>
    )
}

import React from 'react'

export default function Languages() {

    

    return (
        <div className='lang-design'>
            <div id='google_translate_element'></div>
            {/* <button id='google_translate_element'></button> */}
        </div>
    )
}

import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

export default function Header() {
    const navigate = useNavigate()
    const handleActive = (id) => {
        localStorage.setItem('activeBtn', id)
    }

    const activeBtn = localStorage.getItem('activeBtn')
    const handleSignOut = () => {
        localStorage.removeItem('driverID');
        localStorage.removeItem('tranlate');
        localStorage.removeItem('tranlatelogin');
        navigate("/")
    }
    return (
        <header className='header'>
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid container">
                    <a className="navbar-brand" href="#">Vfleet</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto mb-2 mb-lg-0 ">
                            <li className="nav-item">
                                <a className={activeBtn == '0' ? "nav-link active" : "nav-link"} aria-current="page" href="/home" onClick={(e) => handleActive(0)}>Home</a>
                            </li>
                            <li className="nav-item">
                                <a className={activeBtn == '1' ? "nav-link active" : "nav-link"} aria-current="page" href="/profile" onClick={(e) => handleActive(1)}>Profile</a>
                            </li>
                            <li className="nav-item">
                                <a className='nav-link' aria-current="page" href="" onClick={(e) => handleSignOut()}>Sign out</a>
                            </li>
                            {/* <li className="nav-item">
                        <a className="nav-link" href="#">Contact</a>
                    </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

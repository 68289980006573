import React, { useEffect, useState } from 'react'
import Header from './Header'
import { api_url } from './constant'
import moment from 'moment'
import Footer from './Footer'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
export default function DriverForm() {

    const tripid = useParams();
    console.log("tripid", tripid.id);
    const navigate = useNavigate();
    const driv_id = localStorage.getItem("driverID");

    const initialPrice = [
        {
            t_other_amount: "",
            t_other_expense_notes: "",

        },
    ];
    const [counter, setCounter] = useState(initialPrice);
    const [userData, setUserData] = useState();
    const [loading, setLoading] = useState("0");
    const [errorload, setErrorload] = useState(false);

    const [userTripdetail, setUserTripdetail] = useState({
        t_start_meter: "",
        t_end_meter: "",
        t_fastag: "",
        v_fuel_quantity: "",
        v_fuelprice: "",
        v_fuelfilldate: "",
        v_odometerreading: "",
        v_fuelcomments: ""
    });

    const getUserData = async () => {

        const responce = await fetch(api_url + `driverdetails/tripstatus/${tripid.id}`, {
            method: "get",
            headers: {
                'content-type': "application/json"
            }
        })
        const data = await responce.json();

        console.log("innre--data", data)
        setUserData(data?.trip_data);
    }

    const handleData = (e) => {
        const { name, value } = e.target
        setUserTripdetail({
            ...userTripdetail,
            [name]: value,
        });
    }

    console.log("userTripdetail", userTripdetail)
    const handlePriceValue = (e, id) => {
        const { name, value } = e.target;
        const list = [...counter];
        list[id][name] = value;


        if (id === 0) {
            //   setInputValue({ ...inputValue, [name]: value });
        }

        // counter.filter((val) => {return (val.price_type !== "event_price")})
        // if (id !== 0) {
        //   list[id][name] = value;
        // }

        setCounter(list);
    };
    /* Add new input field for price */
    const removeFirstObject = () => {
        // Create a copy of the array without the first object
        const newArray = [...counter.slice(1)];

        // Update the state with the new array
        setCounter(newArray);
    };
    const addNewPrice = (e, id) => {
        let newFields = {
            t_other_amount: "",
            t_other_expense_notes: "",
        };

        setCounter([...counter, newFields]);
    };

    const removeInputFields = (index) => {

        const data = [...counter];
        data?.splice(index, 1);
        setCounter(data);
    };


    useEffect(() => {
        getUserData();
    }, [])


    const parseSerializedArray = (str) => {
        const regex = /s:(\d+):\"(.*?)\";/g;
        const matches = str?.match(regex);
        return matches?.map(match => match.match(/s:(\d+):\"(.*?)\";/).slice(1, 3));
    };

    // Parse serialized PHP arrays
    const amountArray = parseSerializedArray(userData?.other_expense_amount);
    const notesArray = parseSerializedArray(userData?.other_expense_notes);

    // Create the desired array structure
    const resultArray = amountArray?.map((item, index) => ({
        t_other_amount: item[1],
        t_other_expense_notes: notesArray[index][1],
    }));




    console.log("counter", counter);

    useEffect(() => {

        if (resultArray?.length > 0) {
            setCounter(resultArray);
        }
    }, [userData]);

    console.log("data user ", userData);

    const t_other_amount = counter?.map((items) => items?.t_other_amount);
    const t_other_expense_notes = counter?.map((items) => items?.t_other_expense_notes);


    const onSubmitData = async (e) => {

        if ((!userTripdetail?.t_start_meter && !userData?.start_meter) || (!userData?.end_meter && !userTripdetail?.t_end_meter)) {
            setErrorload(true);
            toast.warning("Please Fill All Data");
        } else {
            axios
                .post(api_url + "driverdetails/tripcomplete/", {
                    "t_id": tripid.id,
                    "v_fueladdedby": driv_id,
                    "v_id": userData?.vehicle_id,
                    "t_start_meter": userTripdetail?.t_start_meter ? userTripdetail?.t_start_meter : userData?.start_meter,
                    "t_end_meter": userTripdetail?.t_end_meter ? userTripdetail?.t_end_meter : userData?.end_meter,
                    "t_fastag": userTripdetail?.t_fastag ? userTripdetail?.t_fastag : userData?.fastag_amount,
                    "v_fuel_quantity": userTripdetail?.v_fuel_quantity,
                    "v_fuelprice": userTripdetail?.v_fuelprice,
                    "v_fuelfilldate": userTripdetail?.v_fuelfilldate,
                    "v_odometerreading": userTripdetail?.v_odometerreading,
                    "v_fuelcomments": userTripdetail?.v_fuelcomments,
                    "t_other_expense_notes": t_other_expense_notes,
                    "t_other_amount": t_other_amount,
                })
                .then((res) => {
                    setErrorload(false);
                    setLoading("1");
                    toast.success(res.data.messages);
                    setLoading("0");
                    setTimeout(() => {
                        navigate("/home");
                    }, 1500);
                })
                .catch((error) => {
                    console.error("error", error);
                    setLoading("0");
                    toast.warning(error);
                });
        }
    };


    return (
        <>
            {/* header section start */}
            <Header />
            {/* header section start */}
            <main className='main-content'>
                <section id="contact" className="contact">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                            <h2>Contact</h2>
                            {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
                        </div>


                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='upcoming-header-acc tripformtop'>
                                    <div className='upcoming-header-top'>
                                        <div className='content px-0'>
                                            <div className='trip-go' id="driverform">
                                                <div className="trip-from"><h2><span>{moment(userData?.trip_start_date).format("hh:mm")}</span></h2><p><span>{userData?.trip_from_location}</span></p>
                                                    <span className="blur-text">{moment(userData?.trip_start_date).format('DD/MM/YYYY')}</span></div>

                                                <div className="trip-icon"><h2>{userData?.trip_type} </h2>
                                                    {userData?.trip_type == "singletrip"
                                                        ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                                            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                                                        </svg></p>
                                                        :
                                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-right" viewBox="0 0 16 16">
                                                            <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5" />
                                                        </svg>
                                                        </p>

                                                    }
                                                    <span className="blur-text">{userData?.trip_distance + "km"} </span>
                                                    </div>

                                                <div className="trip-to text-end"><h2>06:40</h2><p><span>{userData?.trip_to_location}</span></p>
                                                    <span className="blur-text">{moment(userData?.trip_end_date).format('DD/MM/YYYY')}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className="accordian-body-inner accordian-body-top"><div className="left-box"><h2>passenger name</h2>
                                        <p className="title"><span>{userData?.customer_name}</span></p>
                                    </div>
                                        <div className="center-box"><h2>total passenger</h2>
                                            <p className="title"><span>{userData?.no_of_passenger}</span></p></div>
                                        <div className="right-box"><h2>passenger contact</h2>
                                            <p className="title">Call:<a className="call-no" href="7974293281"> <span>{userData?.customer_mobile}</span></a></p>
                                        </div></div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mt-5 mt-lg-0 d-flex align-items-stretch">
                                <div className="driver-form">
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="t_start_meter">Start Meeter Reading <span className='required'>*</span></label>
                                            <input type="number" className={!userTripdetail?.t_start_meter && !userData?.start_meter && errorload == true ? "form-control error-border" : "form-control"} defaultValue={userData?.start_meter} name="t_start_meter" onChange={(e) => handleData(e)} required />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="t_end_meter">End Meeter Reading <span className='required'>*</span></label>
                                            <input type="number" className={!userData?.end_meter && !userTripdetail?.t_end_meter && errorload == true ? "form-control error-border" : "form-control"} defaultValue={userData?.end_meter} onChange={(e) => handleData(e)} name="t_end_meter" required />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="t_fastag">Fastag Charge</label>
                                            <input type="number" className="form-control" defaultValue={userData?.fastag_amount} onChange={(e) => handleData(e)} name="t_fastag" required />
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label htmlFor="v_fuel_quantity">Fuel in Liter</label>
                                            <input type="number" className="form-control" onChange={(e) => handleData(e)} name="v_fuel_quantity" required />
                                        </div>


                                        <div className="form-group col-md-6">
                                            <label htmlFor="v_fuelprice">Fuel Amount</label>
                                            <input type="number" className="form-control" name="v_fuelprice" onChange={(e) => handleData(e)} required />
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label htmlFor="v_fuelfilldate">Fuel Fill Date</label>
                                            <input type="date" className="form-control" name="v_fuelfilldate" onChange={(e) => handleData(e)} required />
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label htmlFor="v_odometerreading">Fuel Meter Reading</label>
                                            <input type="text" className="form-control" name="v_odometerreading" onChange={(e) => handleData(e)} required />
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label htmlFor="v_fuelcomments">Fuel Comments</label>
                                            <textarea className="form-control" name="v_fuelcomments" onChange={(e) => handleData(e)} required />
                                        </div>



                                        {/* price add start*/}
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <h5 className='text-decoration-underline'>Other Expenses</h5>
                                            </div>
                                        </div>

                                        {counter.length > 0
                                            ? counter?.map((val, index) => {
                                                return (
                                                    <>
                                                        <div className="form-group col-md-6 position-relative">
                                                            <div className="row" key={index}>
                                                                {index === 0 ? (
                                                                    <>

                                                                        <div className="col-md-5">
                                                                            <label
                                                                                htmlFor="price"
                                                                                className="form-label"
                                                                            >
                                                                                Price
                                                                            </label>
                                                                            <input
                                                                                type="Number"
                                                                                name="t_other_amount"
                                                                                id="price"
                                                                                className="form-control"
                                                                                placeholder="Price"
                                                                                value={val?.t_other_amount}
                                                                                onChange={(e) =>
                                                                                    handlePriceValue(e, index)
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <label
                                                                                htmlFor="price_type"
                                                                                className="form-label"
                                                                            >
                                                                                Notes
                                                                            </label>
                                                                            <textarea
                                                                                type="text"
                                                                                name="t_other_expense_notes"
                                                                                rows={1}
                                                                                id="price_type"
                                                                                className="form-control"
                                                                                placeholder="Notes"
                                                                                value={val?.t_other_expense_notes}
                                                                                onChange={(e) =>
                                                                                    handlePriceValue(e, index)
                                                                                }
                                                                            />
                                                                        </div>

                                                                    </>
                                                                ) : (
                                                                    <>

                                                                        <div className="col-md-5">
                                                                            <label
                                                                                htmlFor="price"
                                                                                className="form-label"
                                                                            >
                                                                                Price
                                                                            </label>
                                                                            <input
                                                                                type="Number"
                                                                                name="t_other_amount"
                                                                                id="price"
                                                                                className="form-control"
                                                                                placeholder="Price"
                                                                                value={val?.t_other_amount}
                                                                                onChange={(e) =>
                                                                                    handlePriceValue(e, index)
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-5">
                                                                            <label
                                                                                htmlFor="price_type"
                                                                                className="form-label"
                                                                            >
                                                                                Notes
                                                                            </label>
                                                                            <textarea
                                                                                type="text"
                                                                                name="t_other_expense_notes"
                                                                                id="price_type"
                                                                                rows={1}
                                                                                className="form-control"
                                                                                placeholder="Price Type"
                                                                                value={val?.t_other_expense_notes}
                                                                                onChange={(e) =>
                                                                                    handlePriceValue(e, index)
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )}

                                                                <div
                                                                    className="col-md-2 text-end position-relative new_price_add_button"
                                                                    key={index}
                                                                >
                                                                    <label></label>
                                                                    {counter.length - 1 === index && (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-success price_add_new_btn"
                                                                            onClick={(e) => addNewPrice(e, index)}
                                                                        >
                                                                            <span>+</span>
                                                                        </button>
                                                                    )}

                                                                    {counter.length !== 1 && (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-danger price_remove_btn"
                                                                            onClick={() => removeInputFields(index)}
                                                                        >
                                                                            -
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            })
                                            : ""}
                                        {/* price add start*/}
                                    </div>
                                    <div className="text-center"><button type="submit" className='driverbtn' onClick={() => onSubmitData()}>Submit</button></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>
            {/* footer section start */}
            <Footer />
            {/* footer section end */}
        </>
    )
}

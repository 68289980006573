import React, { useEffect, useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import { Hypnosis } from "react-cssfx-loading";
import { api_url,image_url } from './constant'


export default function Profile() {
    const driver_id = localStorage.getItem("driverID");
    const [profileData, setProfileData] = useState(false);
    const [loading, setLoading] = useState([]);
    const handleData = (e) => {
        const { name, value } = e.target
        setProfileData({
            ...profileData,
            [name]: value,
        });
    }
    const profile_data = async () => {
        // axios.post(api_url + `driverdetails/drivertrip`)
        const responce = await fetch(api_url + `driverdetails/profile/${driver_id}`, {
            method: "get",
            headers: {
                'content-type': "application/json"
            }
        })
        const data = await responce.json();
        // console.log("data", data);
        setProfileData(data);
        setLoading(data)

    }
    useEffect(() => {
        profile_data()
    }, [])
    // console.log("profileData", profileData);
    return (
        <>
            {/* header section start */}
            <Header />
            {/* header section start */}
            <main className='main-content'>
                <section className="contact profile">
                    <div className="container" data-aos="fade-up">
                        <div className="section-title">
                            <h2>Profile</h2>
                            {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
                        </div>

                        <div className="row">
                            <div className="col-md-12 mt-5 mt-lg-0 d-flex align-items-stretch">
                                <div className="driver-form profile-page">
                                    {
                                        loading == false ? <Hypnosis className='text-center w-100 my-3' color="#37517e" width="40px" height="40px" duration="3s" /> :

                                            <div className="row">
                                                <div className="form-group col-md-12">
                                                    <div className='profile-box text-center'>
                                                        <div className='profileImg'>
                                                            <img src= {image_url + profileData.d_file} />
                                                        </div>
                                                        <div className='content'>
                                                            <h2 >{profileData.d_name}</h2>
                                                            <p className='text-muted'><span>{profileData?.d_is_active == "1" ? 'Active' : 'Deactive'}</span> | Age {profileData.d_age}<span></span></p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="d_licenseno">License Number</label>
                                                    <input type="text" className="form-control" name="d_licenseno" value={profileData.d_licenseno} disabled />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="d_license_expdate">License Expire Date</label>
                                                    <input type="text" className="form-control" name="d_license_expdate" value={profileData.d_license_expdate} disabled />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="d_mobile">Phone Number</label>
                                                    <input type="number" className="form-control" value={profileData.d_mobile} disabled />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="d_doj">Join Date</label>
                                                    <input type="text" className="form-control" value={profileData.d_doj} name="d_doj" disabled />
                                                </div>

                                                <div className="form-group col-md-6">
                                                    <label htmlFor="d_aadhar">Aadhar</label>
                                                    <input type="Number" className="form-control" name="d_aadhar" value={profileData.d_aadhar} disabled />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="d_ref">Refrence</label>
                                                    <input type="text" className="form-control" value={profileData.d_ref} name="d_ref" disabled />
                                                </div>

                                                <div className="form-group col-md-12">
                                                    <label htmlFor="d_address">Address</label>
                                                    <textarea className="form-control textArea" name="d_address" value={profileData.d_address} disabled />
                                                </div>


                                            </div>
                                    }
                                </div>

                            </div>
                        </div>

                    </div>
                </section>
            </main>
            {/* footer section start */}
            <Footer />
            {/* footer section end */}
        </>
    )
}

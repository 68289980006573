import React, { useEffect, useState } from 'react'
import { api_url } from './constant'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment'
import tripimg from '../trip-icon.png'
import tripway from '../trip-way.gif'
import warning from '../warning.jpg'
import { Hypnosis } from "react-cssfx-loading";
import Languages from './Languages';
export default function Trip() {
    
    const navigate = useNavigate();
    const [tripData, setTripData] = useState(false);
    const [loading, setLoading] = useState([]);
    const driverID = localStorage.getItem('driverID');

    const gerttranslate =  localStorage.getItem("tranlate")


 
    const trip_data = async () => {
        // axios.post(api_url + `driverdetails/drivertrip`)
        const responce = await fetch(api_url + `driverdetails/drivertrip/${driverID}`, {
            method: "get",
            headers: {
                'content-type': "application/json"
            }
        })
        const data = await responce.json();
        setTripData(data);
        setLoading(data)

    }
    useEffect(() => {
        trip_data()
    }, [])
    // console.log("tripData", tripData);
    // form url
    const handleFromSub = (id) => {
        navigate(`/trip/${id}`)

    }

    const [currentDateTime, setCurrentDateTime] = useState('');

    useEffect(() => {
        const updateDateTime = () => {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');

            const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
            setCurrentDateTime(formattedDateTime);
        };

        // Update the date and time initially and then every second
        updateDateTime();
        const intervalId = setInterval(updateDateTime, 1000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);


useEffect(()=>{

    if(!gerttranslate){ 
        window.location.reload();
        // localStorage.getItem('tranlate')
        localStorage.setItem("tranlate", "tranlate")
    } 

},[gerttranslate])
 


    const yettostart = (tripData && tripData?.yettostartTrips && tripData?.yettostartTrips.length > 0) ? true : false;

    const cancelled = (tripData && tripData?.cancelledTrips && tripData?.cancelledTrips.length > 0) ? true : false;

    const completed = (tripData && tripData?.completedTrips && tripData?.completedTrips.length > 0) ? true : false;

    const ongoing = (tripData && tripData?.ongoingTrips && tripData?.ongoingTrips.length > 0) ? true : false;

    console.log("tripdata", tripData)
const Today_data = new Date()
const todatData = moment(Today_data).format("DD/MM/YYYY");
console.log("todatData",todatData);

    return (
        <section id="trip" className="trip section-bg">

            <div className="container" data-aos="fade-up">
                 {gerttranslate ? <Languages></Languages> : <Languages></Languages> } 
                 {/* <Languages />  */}
              
                <div className="section-title">
                    <h2>Trip</h2>
                    {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
                </div>

                <div className='trip-box'>
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-ongoing-tab" data-bs-toggle="pill" data-bs-target="#pills-ongoing" type="button" role="tab" aria-controls="pills-ongoing" aria-selected="true">Ongoing</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-upcoming-tab" data-bs-toggle="pill" data-bs-target="#pills-upcoming" type="button" role="tab" aria-controls="pills-upcoming" aria-selected="true">Upcoming</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-complate-tab" data-bs-toggle="pill" data-bs-target="#pills-complate" type="button" role="tab" aria-controls="pills-complate" aria-selected="false">Completed</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-cancel-tab" data-bs-toggle="pill" data-bs-target="#pills-cancel" type="button" role="tab" aria-controls="pills-cancel" aria-selected="false">Canceled</button>
                        </li>
                    </ul>
                    {
                        loading == false ? <Hypnosis className='text-center w-100 my-3' color="#37517e" width="40px" height="40px" duration="3s" /> :
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-ongoing" role="tabpanel" aria-labelledby="pills-ongoing-tab">
                                    <div className='ongoing-box trip-accordian'>
                                        <div className="accordion accordion-flush" id="accordionFlushonExample">
                                            {/* on going trip map start here */}
                                            <p className='text-center'>
                                                {ongoing == false ? "No Data" : ""}</p>
                                            {tripData?.ongoingTrips?.map((item, index) => {
                                                return (
                                                    <div className="accordion-item">
                                                       
                                                        <h2 className="accordion-header" id={`flush-headingon${index}`}>

                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapseon${index}`} aria-expanded="false" aria-controls={`flush-collapseon${index}`}>
                                                         
                                                                <div className='upcoming-header-acc'>
                                                                    <div className='upcoming-header-top'>
                                                                        <div className='img'>
                                                                            <img src={tripimg} alt='img' />
                                                                        </div>
                                                                        <div className='content'>
                                                                            <p className='pendingBox'>
                                                                             
                                                                        {moment(item?.trip_start_date).format('DD/MM/YYYY') < todatData ? <p className='pendingData'><span> &#x26A0;</span> Please fill form</p> : ""}
                                                                       
                                                                        </p>
                                                                            <div className='trip-go'>
                                                                           
                                                                                <div className='trip-from'>
                                                                                    <h2>
                                                                                        
                                                                                        {moment(item?.trip_start_date).format("HH") >= 12
                                                                                            ? moment(item?.trip_start_date).format("hh:mm") + " PM"
                                                                                            : moment(item?.trip_start_date).format("hh:mm") + " AM"
                                                                                        }
                                                                                    </h2>
                                                                                    <p><span>{item?.trip_from_location}</span></p>
                                                                                    <span className='blur-text'>{moment(item?.trip_start_date).format('DD/MM/YYYY')}</span>
                                                                                </div>
                                                                                <div className='trip-icon'>
                                                                                    <h2><span>{item?.trip_type}</span> </h2>
                                                                                    {item?.trip_type == "singletrip"
                                                                                        ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                                                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                                                                                        </svg></p>
                                                                                        :
                                                                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-right" viewBox="0 0 16 16">
                                                                                            <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5" />
                                                                                        </svg>
                                                                                        </p>
                                                                                    }
                                                                                    <span className='blur-text'>{item?.trip_distance + "km"} </span>
                                                                                </div>
                                                                                <div className='trip-to text-end'>
                                                                                    <h2><span>{moment(item?.trip_end_date).format('HH') >= 12 ?
                                                                                        moment(item?.trip_end_date).format('hh:mm') + "PM"
                                                                                        :
                                                                                        moment(item?.trip_end_date).format('hh:mm') + "AM"}</span></h2>
                                                                                    <p><span>{item?.trip_to_location}</span></p>
                                                                                    <span className='blur-text'>{moment(item?.trip_end_date).format('DD/MM/YYYY')}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        </h2>
                                                        <div id={`flush-collapseon${index}`} className="accordion-collapse collapse" aria-labelledby={`flush-headingon${index}`} data-bs-parent="#accordionFlushonExample">
                                                            <div className="accordion-body">
                                                                <div className='trip-accordian-body'>
                                                                    <div className='accordian-body-inner accordian-body-top'>
                                                                        <div className='left-box'>
                                                                            <h2>passenger name</h2>
                                                                            <p className='title'><span>{item?.customer_name}</span></p>
                                                                        </div>
                                                                        <div className='center-box'>
                                                                            <h2>total passenger</h2>
                                                                            <p className='title'><span>{item?.no_of_passenger}</span></p>
                                                                        </div>

                                                                        <div className='right-box'>
                                                                            <h2>passenger contact</h2>
                                                                            <p className='title'>Call:<a className='call-no' href={item?.customer_mobile}><span>{item?.customer_mobile}</span></a></p>
                                                                        </div>
                                                                    </div>
                                                                    <p className='hr-border'> </p>
                                                                    <div className='accordian-body-inner accordian-body-middle'>
                                                                        <div className='left-box'>
                                                                            <h2>vehicle model</h2>
                                                                            <p className='title'><p><span>({item?.vehicle_model})</span></p></p>

                                                                        </div>
                                                                        <div className='center-box'>
                                                                            <h2>vehicle name / number</h2>
                                                                            <p className='title'><span>{item?.vehicle_name} / {item?.registration_no}</span></p>

                                                                        </div>

                                                                        <div className='right-box'>
                                                                            <h2>vehicle seat</h2>
                                                                            <p className='title'><span>{item?.v_seats ? item?.v_seats : "-"}</span></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='form-box'>
                                                                    <button
                                                                        onClick={() => handleFromSub(item?.trip_id)}
                                                                        className="btn tripForm"
                                                                    >Submit Data</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            currentDateTime >= item.trip_start_date && currentDateTime <= item.trip_end_date ? <div className='ongoing-img'><img src={tripway} alt='img' /></div> : ""
                                                        }

                                                    </div>
                                                )
                                            })}
                                            {/* on going trip map end here */}
                                        </div>


                                    </div>
                                </div>

                                <div className="tab-pane fade" id="pills-upcoming" role="tabpanel" aria-labelledby="pills-upcoming-tab">
                                    <div className='upcoming-box trip-accordian'>
                                        <div className="accordion accordion-flush" id="accordionFlushyetExample">


                                            {/* yettostartTrips map start here */}
                                           <p className='text-center'> {yettostart == false ? "No Data" : ""}</p>
                                            {tripData?.yettostartTrips?.map((item, index) => {
                                                return (
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id={`flush-headingyet${index}`}>
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapseyet${index}`} aria-expanded="false" aria-controls={`flush-collapseyet${index}`}>
                                                                <div className='upcoming-header-acc'>
                                                                    <div className='upcoming-header-top'>
                                                                        <div className='img'>
                                                                            <img src={tripimg} alt='img' />
                                                                        </div>
                                                                        <div className='content'>
                                                                            {/* <h2>Express</h2> */}
                                                                            <div className='trip-go'>
                                                                                <div className='trip-from'>
                                                                                    <h2>
                                                                                        
                                                                                        {moment(item?.trip_start_date).format("HH") >= 12
                                                                                            ? moment(item?.trip_start_date).format("hh:mm") + " PM"
                                                                                            : moment(item?.trip_start_date).format("hh:mm") + " AM"
                                                                                        }
                                                                                    </h2>
                                                                                    <p>{item?.trip_from_location}</p>
                                                                                    <span className='blur-text'>{moment(item?.trip_start_date).format('DD/MM/YYYY')}</span>
                                                                                </div>
                                                                                <div className='trip-icon'>
                                                                                    <h2>{item?.trip_type} </h2>
                                                                                    {item?.trip_type == "singletrip"
                                                                                        ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                                                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                                                                                        </svg></p>
                                                                                        :
                                                                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-right" viewBox="0 0 16 16">
                                                                                            <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5" />
                                                                                        </svg>
                                                                                        </p>
                                                                                    }
                                                                                    <span className='blur-text'>{item?.trip_distance} km</span>
                                                                                </div>
                                                                                <div className='trip-to text-end'>
                                                                                    <h2>{moment(item?.trip_end_date).format('HH') >= 12 ?
                                                                                        moment(item?.trip_end_date).format('hh:mm') + "PM"
                                                                                        :
                                                                                        moment(item?.trip_end_date).format('hh:mm') + "AM"}</h2>
                                                                                    <p>{item?.trip_to_location}</p>
                                                                                    <span className='blur-text'>{moment(item?.trip_end_date).format('DD/MM/YYYY')}</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </button>
                                                        </h2>
                                                        <div id={`flush-collapseyet${index}`} className="accordion-collapse collapse" aria-labelledby={`flush-headingyet${index}`} data-bs-parent="#accordionFlushyetExample">
                                                            <div className="accordion-body">
                                                                <div className='trip-accordian-body'>
                                                                    <div className='accordian-body-inner accordian-body-top'>
                                                                        <div className='left-box'>
                                                                            <h2>passenger name</h2>
                                                                            <p className='title'>{item?.customer_name}</p>
                                                                        </div>
                                                                        <div className='center-box'>
                                                                            <h2>total passenger</h2>
                                                                            <p className='title'>{item?.no_of_passenger}</p>
                                                                        </div>

                                                                        <div className='right-box'>
                                                                            <h2>passenger contact</h2>
                                                                            <p className='title'>Call:<a className='call-no' href={item?.customer_mobile}>{item?.customer_mobile}</a></p>
                                                                        </div>
                                                                    </div>
                                                                    <p className='hr-border'> </p>
                                                                    <div className='accordian-body-inner accordian-body-middle'>
                                                                        <div className='left-box'>
                                                                            <h2>vehicle model</h2>
                                                                            <p className='title'><p>({item?.vehicle_model})</p></p>
                                                                        </div>
                                                                        <div className='center-box'>
                                                                            <h2>vehicle name / number</h2>
                                                                            <p className='title'>{item?.vehicle_name} / {item?.registration_no}<p></p></p>

                                                                        </div>

                                                                        <div className='right-box'>
                                                                            <h2>vehicle seat</h2>
                                                                            <p className='title'>{item?.v_seats ? item?.v_seats : "-"}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='form-box'>
                                                                    <button
                                                                        onClick={() => handleFromSub(item?.trip_id)}
                                                                        className="btn tripForm"
                                                                    >Submit Data</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            currentDateTime >= item.trip_start_date && currentDateTime <= item.trip_end_date ? <div className='ongoing-img'><img src={tripway} alt='img' /></div> : ""
                                                        }
                                                    </div>
                                                )
                                            })}
                                            {/* yettostartTrips map end here */}
                                        </div>


                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-complate" role="tabpanel" aria-labelledby="pills-complate-tab">
                                    <div className='complete-box trip-accordian'>
                                        <div className="accordion accordion-flush" id="accordionFlushcomExample">
                                            {/* on going completre trip map start here */}
                                            <p className='text-center'>  {completed == false ? "No Data" : ""}</p>
                                            {tripData?.completedTrips?.map((item, index) => {
                                                return (
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id={`flush-headingcom${index}`}>
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapsecom${index}`} aria-expanded="true" aria-controls={`flush-collapsecom${index}`}>
                                                                <div className='upcoming-header-acc'>
                                                                    <div className='upcoming-header-top'>
                                                                        <div className='img'>
                                                                            <img src={tripimg} alt='img' />
                                                                        </div>
                                                                        <div className='content'>
                                                                            {/* <h2>Express</h2> */}
                                                                            <div className='trip-go'>
                                                                                <div className='trip-from'>
                                                                                    <h2>
                                                                                        
                                                                                        {moment(item?.trip_start_date).format("HH") >= 12
                                                                                            ? moment(item?.trip_start_date).format("hh:mm") + " PM"
                                                                                            : moment(item?.trip_start_date).format("hh:mm") + " AM"
                                                                                        }
                                                                                    </h2>
                                                                                    <p>{item?.trip_from_location}</p>
                                                                                    <span className='blur-text'>{moment(item?.trip_start_date).format('DD/MM/YYYY')}</span>
                                                                                </div>
                                                                                <div className='trip-icon'>
                                                                                    <h2>{item?.trip_type} </h2>
                                                                                    {item?.trip_type == "singletrip"
                                                                                        ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                                                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                                                                                        </svg></p>
                                                                                        :
                                                                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-right" viewBox="0 0 16 16">
                                                                                            <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5" />
                                                                                        </svg>
                                                                                        </p>
                                                                                    }
                                                                                    <span className='blur-text'>{item?.trip_distance} km</span>
                                                                                </div>
                                                                                <div className='trip-to text-end'>
                                                                                    <h2>{moment(item?.trip_end_date).format('HH') >= 12 ?
                                                                                        moment(item?.trip_end_date).format('hh:mm') + "PM"
                                                                                        :
                                                                                        moment(item?.trip_end_date).format('hh:mm') + "AM"}</h2>
                                                                                    <p>{item?.trip_to_location}</p>
                                                                                    <span className='blur-text'>{moment(item?.trip_end_date).format('DD/MM/YYYY')}</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </button>
                                                        </h2>
                                                        <div id={`flush-collapsecom${index}`} className="accordion-collapse collapse" aria-labelledby={`flush-headingcom${index}`} data-bs-parent="#accordionFlushcomExample">
                                                            <div className="accordion-body">
                                                                <div className='trip-accordian-body'>
                                                                    <div className='accordian-body-inner accordian-body-top'>
                                                                        <div className='left-box'>
                                                                            <h2>passenger name</h2>
                                                                            <p className='title'>{item?.customer_name}</p>
                                                                        </div>
                                                                        <div className='center-box'>
                                                                            <h2>total passenger</h2>
                                                                            <p className='title'>{item?.no_of_passenger}</p>
                                                                        </div>

                                                                        <div className='right-box'>
                                                                            <h2>passenger contact</h2>
                                                                            <p className='title'>Call:<a className='call-no' href={item?.customer_mobile}>{item?.customer_mobile}</a></p>
                                                                        </div>
                                                                    </div>
                                                                    <p className='hr-border'> </p>
                                                                    <div className='accordian-body-inner accordian-body-middle'>
                                                                        <div className='left-box'>
                                                                            <h2>vehicle model</h2>
                                                                            <p className='title'><p>({item?.vehicle_model})</p></p>

                                                                        </div>
                                                                        <div className='center-box'>
                                                                            <h2>vehicle name / number</h2>
                                                                            <p className='title'>{item?.vehicle_name} / {item?.registration_no}<p></p></p>

                                                                        </div>

                                                                        <div className='right-box'>
                                                                            <h2>vehicle seat</h2>
                                                                            <p className='title'>{item?.v_seats ? item?.v_seats : "-"}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            {/* on going completre trip map end here */}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-cancel" role="tabpanel" aria-labelledby="pills-cancel-tab">
                                    <div className='cancle-box trip-accordian'>
                                        <div className="accordion accordion-flush" id="accordionFlushcanExample">
                                            {/* on going cancel trip map start here */}
                                            <p className='text-center'>   {cancelled == false ? "No Data" : ""}</p>
                                            {tripData?.cancelledTrips?.map((item, index) => {
                                                return (
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id={`flush-headingcan${index}`}>
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapsecan${index}`} aria-expanded="true" aria-controls={`flush-collapsecan${index}`}>
                                                                <div className='upcoming-header-acc'>
                                                                    <div className='upcoming-header-top'>
                                                                        <div className='img'>
                                                                            <img src={tripimg} alt='img' />
                                                                        </div>
                                                                        <div className='content'>
                                                                            {/* <h2>Express</h2> */}
                                                                            <div className='trip-go'>
                                                                                <div className='trip-from'>
                                                                                    <h2>
                                                                                        
                                                                                        {moment(item?.trip_start_date).format("HH") >= 12
                                                                                            ? moment(item?.trip_start_date).format("hh:mm") + " PM"
                                                                                            : moment(item?.trip_start_date).format("hh:mm") + " AM"
                                                                                        }
                                                                                    </h2>
                                                                                    <p>{item?.trip_from_location}</p>
                                                                                    <span className='blur-text'>{moment(item?.trip_start_date).format('DD/MM/YYYY')}</span>
                                                                                </div>
                                                                                <div className='trip-icon'>
                                                                                    <h2>{item?.trip_type} </h2>
                                                                                    {item?.trip_type == "singletrip"
                                                                                        ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                                                                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                                                                                        </svg></p>
                                                                                        :
                                                                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-right" viewBox="0 0 16 16">
                                                                                            <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5" />
                                                                                        </svg>
                                                                                        </p>
                                                                                    }
                                                                                    <span className='blur-text'>{item?.trip_distance} km</span>
                                                                                </div>
                                                                                <div className='trip-to text-end'>
                                                                                    <h2>{moment(item?.trip_end_date).format('HH') >= 12 ?
                                                                                        moment(item?.trip_end_date).format('hh:mm') + "PM"
                                                                                        :
                                                                                        moment(item?.trip_end_date).format('hh:mm') + "AM"}</h2>
                                                                                    <p>{item?.trip_to_location}</p>
                                                                                    <span className='blur-text'>{moment(item?.trip_end_date).format('DD/MM/YYYY')}</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </button>
                                                        </h2>
                                                        <div id={`flush-collapsecan${index}`} className="accordion-collapse collapse" aria-labelledby={`flush-headingcan${index}`} data-bs-parent="#accordionFlushcanExample">
                                                            <div className="accordion-body">
                                                                <div className='trip-accordian-body'>
                                                                    <div className='accordian-body-inner accordian-body-top'>
                                                                        <div className='left-box'>
                                                                            <h2>passenger name</h2>
                                                                            <p className='title'>{item?.customer_name}</p>
                                                                        </div>
                                                                        <div className='center-box'>
                                                                            <h2>total passenger</h2>
                                                                            <p className='title'>{item?.no_of_passenger}</p>
                                                                        </div>

                                                                        <div className='right-box'>
                                                                            <h2>passenger contact</h2>
                                                                            <p className='title'>Call:<a className='call-no' href={item?.customer_mobile}>{item?.customer_mobile}</a></p>
                                                                        </div>
                                                                    </div>
                                                                    <p className='hr-border'> </p>
                                                                    <div className='accordian-body-inner accordian-body-middle'>
                                                                        <div className='left-box'>
                                                                            <h2>vehicle model</h2>
                                                                            <p className='title'><p>({item?.vehicle_model})</p></p>

                                                                        </div>
                                                                        <div className='center-box'>
                                                                            <h2>vehicle name / number</h2>
                                                                            <p className='title'>{item?.vehicle_name} / {item?.registration_no}<p></p></p>

                                                                        </div>

                                                                        <div className='right-box'>
                                                                            <h2>vehicle seat</h2>
                                                                            <p className='title'>{item?.v_seats ? item?.v_seats : "-"}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div> {/* on going completre trip map end here */}

                                </div>
                            </div>
                    }
                </div>

            </div>
        </section>
    )
}

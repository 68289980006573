import React from 'react'

export default function Footer() {
  return (
    <footer className='footer'>
                <div className="container footer-bottom clearfix">
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className="copyright">
                            &copy; Copyright <strong><span>Vfleet</span></strong>. All Rights Reserved
                        </div>
                        <div className="credits">

                            Designed by <a href="https://hashtaglabs.biz/" target='_blank'>hashtaglabs</a>
                        </div>
                    </div>
                </div>
            </footer>
  )
}

import React,{useRef} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './Component/Login';
import Home from './Component/Home'
import DriverForm from './Component/DriverForm'
import Profile from './Component/Profile'
function App() {

  
  return (
    <>
  
      <BrowserRouter>
        <Routes>
          <Route index path='/' element={<Login />} />
          <Route index path='/home' element={<Home />} />
          <Route  path='/trip/:id' element={<DriverForm />} />
          <Route path='/profile' element={<Profile/>}/>
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;

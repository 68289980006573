import React from 'react'
import Trip from './Trip'
import DriverForm from './DriverForm'

export default function MainContent() {
    return (
        <>
            <main className='main-content'>
                
                {/* ======= Team Section =======  */}
                <Trip />
                {/* End Team Section  */}
                {/* ======= Contact Section =======  */}
                {/* <DriverForm /> */}
                {/* End Contact Section  */}
            </main>
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { api_url } from './constant'
import { toast } from 'react-toastify';
import Languages from './Languages';

export default function Login() {
    const navigate = useNavigate();

    const tranlateLogin =  localStorage.getItem("tranlatelogin")

    const [inputValue, setInputValue] = useState({});
    console.log(`${api_url}driverdetails/driverlogin`);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("without json",inputValue);
        try {
            const response = await fetch(api_url + 'driverdetails/driverlogin/', {
                method: 'POST',
                body: JSON.stringify(inputValue),
                headers: {
                    "content-type": 'application/json',
                }
            })
            const data = await response.json();
        
            localStorage.setItem('driverID',data.driver_id)
            localStorage.setItem('tranlatelogin','tranlatelogin')
            if (data.status == false) {
                toast.error(data.messages);
            } else {
                toast.success(data.messages);
                setTimeout(()=>{
                 navigate("/home")
                },4000)
            }
        } catch (error) {
            console.log("Error fetching data:", error);
        }
       
    }

    useEffect(()=>{

        if(!tranlateLogin){ 
            window.location.reload();
            // localStorage.getItem('tranlate')
            localStorage.setItem("tranlatelogin", "tranlatelogin")
           
        } 
    
    },[tranlateLogin])
     


    return (
        <div className='Login2'>
            <div id="bg"></div>
            <form onSubmit={handleSubmit}>
          {tranlateLogin ?  <Languages /> : <Languages />}  

                <div className="form-field">
                    <input type="number" placeholder="Mobie Number" required name='mobile' value={inputValue.mobile} onChange={handleChange} />
                </div>

                <div className="form-field">
                    <input type="password" placeholder="Password" required name='password' value={inputValue.password} onChange={handleChange} />
                </div>

                <div className="form-field">
                    <button className="btn" type="submit">Log in</button>
                </div>
            </form>
        </div>
    )
}
